module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#ffffff","theme_color":"#e65013ff","display":"minimal-ui","icon":"src/images/promlens-logo.svg","icons":[{"src":"src/images/favicons/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"src/images/favicons/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"b079347f43d078bcbf3426b0a221c5f8"},
    },{
      plugin: require('../node_modules/gatsby-plugin-plausible/gatsby-browser.js'),
      options: {"plugins":[],"domain":"promlens.com","proxyScript":"/js/script.js","proxyApi":"/api/event"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
